'use strict';

(function () {
  class ListFindingCtrl {
    constructor($scope, $rootScope, $state, $uibModal, Cart, usSpinnerService, Finding, Auth, Util, Modal, toastr, ActivityLog) {
      this.$scope = $scope;
      this.$rootScope = $rootScope;
      this.$uibModal = $uibModal;
      this.Cart = Cart;
      this.Auth = Auth;
      this.isAdmin = Auth.isAdmin;
      this.isOrganizationUserPlus = Auth.isOrganizationUserPlus;
      this.isOrganizationAdmin = Auth.isOrganizationAdmin;
      this.$state = $state;
      this.Modal = Modal;
      this.toastr = toastr;
      this.turbine = $scope.turbine;
      this.blade = $scope.blade;
      this.usSpinnerService = usSpinnerService;
      this.getDefectRecommendation = Util.getDefectRecommendation;
      this.Finding = Finding;
      this.allFindings = [];
      this.findings = [];
      this.filteredFindings = [];
      // used for print group by blade
      this.components = [];
      this.selectedCategory = 'all';
      this.getClass = Util.getFindingClass;
      this.getFindingCategoryText = Util.getFindingCategoryText;
      this.getCavityInspectionTypeId = Util.getCavityInspectionTypeId;
      this.colXsPrintNumber = 2;
      this.ActivityLog = ActivityLog;
      this.sharedLevel = localStorage.getItem('sharedLevel');
      this.hideRepairedFinding = false;
      this.repairs = [];
      Auth.isLoggedInAsync((loggedIn) => {
        if (loggedIn) {
          this.currentUser = Auth.getCurrentUser();
        } else {
          $state.go('login');
        }
      });
      this.bladePositionMap = {
        'A': 1,
        'B': 2,
        'C': 3
      };

      //Refresh Findings on event
      $scope.$on('bladeComponent', (event, args) => {
        if (args.refresh && this.turbine) {
          this.refreshFindings();
        }
      });

      $scope.$on('repairsReceived', (event, args)=> {
        console.log('repairsReceived', args);
        this.repairs = args;
      });

      $scope.$watch('daterange', (newVal, oldVal) => {
        this.filterFindings();
      });

      //showAllFindingImages
      // $rootScope.$on('showAllFindingImages', (event, args)=> {
      //   this.showAllFindingImages();
      // });

      this.refreshFindings();
    }

    filterFindings() {
      if (this.$scope.daterange && this.$scope.daterange.startDate && this.$scope.daterange.endDate) {
        this.filteredFindings = _.filter(this.findings, (finding) => {
          return moment(finding.date || finding.createdAt).isBetween(this.$scope.daterange.startDate, this.$scope.daterange.endDate);
        });
        if(this.hideRepairedFinding) {
          this.filteredFindings = _.filter(this.filteredFindings, (finding) => {
            return !(_.find(this.repairs, (repair) => {
              return repair.finding && repair.finding._id.toString() == finding._id;
            }));
          });
        }
      }
      else {
        if(this.hideRepairedFinding) {
          this.filteredFindings = this.filteredFindings;
        } else {
          this.filteredFindings = this.findings;
        }
      }

      this.components = ListFindingCtrl.getBladesGroup(this.filteredFindings);
      this.$scope.components = this.components;

    }

    filterResults() {
      if (this.selectedCategory != 'all') {
        this.findings = this.allFindings.filter(finding => finding.category >= this.selectedCategory);
      } else {
        this.findings = this.allFindings.slice();
      }
    }

    showAllFindingImages() {
      _.each(this.findings, (finding) => {
        finding.open = true;
      })
    }

    hideAllFindingImages() {
      _.each(this.findings, (finding) => {
        finding.open = false;
      })
    }

    showRepairedFinding() {
      this.hideRepairedFinding = true;
      this.filteredFindings = _.filter(this.findings, (finding) => {
        return !(_.find(this.repairs, (repair) => {
          return repair.finding && repair.finding._id.toString() == finding._id;
        }));
      });
      if (this.$scope.daterange && this.$scope.daterange.startDate && this.$scope.daterange.endDate) {
        this.filteredFindings = _.filter(this.filteredFindings, (finding) => {
          return moment(finding.date || finding.createdAt).isBetween(this.$scope.daterange.startDate, this.$scope.daterange.endDate);
        });
      }
    }

    showAllFinding() {
      this.hideRepairedFinding = false;
      if (this.$scope.daterange && this.$scope.daterange.startDate && this.$scope.daterange.endDate) {
        this.filteredFindings = _.filter(this.findings, (finding) => {
          return moment(finding.date || finding.createdAt).isBetween(this.$scope.daterange.startDate, this.$scope.daterange.endDate);
        });
      } else {
        this.filteredFindings = this.findings;
      }
    }

    showHideAllFindingBtn() {
      return _.find(this.findings, { open: true });
    }

    getBladePosition(position) {
      // console.log('get blade pos: ', position);
      return this.bladePositionMap[position] ? this.bladePositionMap[position] : position;
    }

    refreshFindings() {
      this.fetchFindings();
    }

    fetchFindings() {
      this.loading = true;
      let qry = { id: this.turbine._id };
      if (this.blade) {
        qry.controller = this.blade._id;
      }
      this.Finding.get(qry).$promise
        .then(response => {
          if (response.data) {
            this.allFindings = response.data.slice();
            this.findings = response.data;
            this.filteredFindings = response.data;
            // console.log('findings: ', this.findings);
            this.components = ListFindingCtrl.getBladesGroup(this.filteredFindings);
            this.$scope.components = this.components;
            this.$rootScope.$broadcast('findingsReceived', response.data);
            //console.log('components: ', this.components);

            this.hideAllFindingImages();
          }
          this.loading = false;
          this.stopSpin('spinner-1');
        })
        .catch(err => {
          console.log(err);
          this.stopSpin('spinner-1');
          this.loading = false;
        })
    }

    static getBladesGroup(allFindings) {
      const findings = allFindings.sort((a, b) => {
        if(!a.blade || !b.blade) {
          console.log('sort error no blade fount: ', a , b);
          return 0;
        }
        return a.blade.position - b.blade.position;
      });
      let allBlades = [];
      let blades = {};
      for (let i = 0; i < findings.length; ++i) {
        let obj = findings[i];
        if (obj.blade && blades[obj.blade._id] === undefined) {
          blades[obj.blade._id] = [];
        }
        if(obj.blade) {
          blades[obj.blade._id].push(obj);
        }

      }
      for (let key in blades) {
        allBlades.push({ findings: blades[key], blade: blades[key][0].blade });
      }
      return allBlades;
    }

    startSpin(spinner) {
      this.usSpinnerService.spin(spinner);
    }

    stopSpin(spinner) {
      this.usSpinnerService.stop(spinner);
    }

    deleteFinding(finding) {
      this.findingDeleteError = null;
      this.Modal.confirm.delete((finding) => {
        // console.log('delete', finding);
        this.Finding.delete({ id: finding._id }).$promise
          .then(() => {
            // console.log('finding deleted');
            _.remove(this.findings, { _id: finding._id });
            this.bladeStr = '';
            if(finding.blade.make) {
              this.bladeStr+=finding.blade.make+' ';
            }
            if(finding.blade.model) {
              this.bladeStr+=finding.blade.model;
            }
            if(finding.blade.name) {
              this.bladeStr+=finding.blade.name;
            }

            this.turbineStr = '';
            if(finding.turbine.make) {
              this.turbineStr+=finding.turbine.make;
            }
            if(finding.turbine.model) {
              this.turbineStr+=finding.turbine.model;
            }
            if(finding.turbine.name) {
              this.turbineStr+=finding.turbine.name;
            }
            
            const activityLogData = {
              site: this.turbine.site._id,
              turbine: finding.turbine._id,
              blade: finding.blade._id,
              finding: finding._id,
              user: this.currentUser._id,
              source: 'web',
              action: 'delete',
              type: 'finding',
              sourceScreen: 'blade',
              eventString: `Finding No <a href="/blade/${finding.blade._id}/finding/${finding._id}">${finding.uniqueReferenceNumber}</a> was deleted in wind farm <a href="/site/${this.turbine.site._id}">${this.turbine.site.name}</a>, turbine <a href="/turbine/${finding.turbine._id}">${this.turbineStr}</a>, blade <a href="/blade/${finding.blade._id}">${this.bladeStr}</a> by ${this.currentUser.name}`
            }
      
            this.ActivityLog.save(activityLogData).$promise
              .then(activityLog => {
                console.log(activityLog);
              })
              .catch(err => {
                console.log('Error inserting activityLog', err);
            });
            this.$state.reload();
            this.$rootScope.$broadcast('updateCounter');
          })
          .catch(err => {
            console.log('err', err);
            this.findingDeleteError = err;
            this.toastr.error('finding not deleted. please try again or contact administrator.');
          })
      })(finding._id, finding);
    }

    moveFinding(finding) {
      var modalInstance = this.$uibModal.open({
        size: 'lg',
        templateUrl: 'components/modal/finding/moveFindingModal/moveFindingModal.html',
        controller: 'MoveFindingModalCtrl',
        controllerAs: 'moveFinding',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          options: {
            title: 'Move Finding',
            finding: finding,
            site: this.turbine.site
          }
        }
      });
      modalInstance.result.then(() => {
        this.fetchFindings();
      });
    }

    editFinding(finding) {
      console.log(finding)
      var modalInstance = this.$uibModal.open({
        size: 'lg',
        templateUrl: 'components/modal/finding/addEditFindingModal/addEditFindingModal.html',
        controller: 'AddEditFindingModalCtrl',
        controllerAs: 'aEFinding',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          options: {
            title: 'Edit Finding',
            finding: finding
          }
        }
      });
      modalInstance.result.then(()=>{
        this.refreshFindings()
      });
    }

    openFullImage(url) {
      var modalInstance = this.$uibModal.open({
        size: 'lg',
        templateUrl: 'components/modal/view-full-image/viewFullImageModal.html',
        controller: 'ViewFullImageModalCtrl',
        controllerAs: 'vm',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          imageUrl: function () { return url }
        }
      });
      modalInstance.result.then(() => {
      });
    }

    resolve(finding) {
      this.Cart.addFinding({ controller: this.Auth.getCurrentUser().organization._id }, { findingId: finding._id }).$promise
        .then(response => {
          this.toastr.success('Finding added to cart');
          this.$rootScope.$broadcast('updateCart', response.data.findings.length);
        })
        .catch(err => {
          if (err.status === 409) {
            this.toastr.error(err.data.meta.error_message);
          } else {
            this.toastr.error('Something went wrong. please try again later.');
          }
        })
      // this.Cart.addFinding(finding);
    }
  }

  angular.module('windmanagerApp')
    .controller('ListFindingCtrl', ListFindingCtrl);
})();
